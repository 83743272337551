import React, { useEffect } from "react"

export default function redirectUrl(props) {
  const { routingData } = props.pageContext

 useEffect(() => {
    location.replace(routingData.to);
  }, [])

 return (
    <div></div>
  )
}